import { render, staticRenderFns } from "./Rule.vue?vue&type=template&id=97aa2ef0&scoped=true&"
import script from "./Rule.vue?vue&type=script&lang=js&"
export * from "./Rule.vue?vue&type=script&lang=js&"
import style0 from "./Rule.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./Rule.vue?vue&type=style&index=1&id=97aa2ef0&lang=scss&scoped=true&"
import style2 from "./Rule.vue?vue&type=style&index=2&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "97aa2ef0",
  null
  
)

export default component.exports