<template>
  <div>
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Add Item -->
        <b-col />
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0)'"
              variant="primary"
              @click="$bvModal.show('modal-rule-create')"
            >
              Aggiungi Rule
            </b-button>
            <rule-modal
              @refetch-data="fetchRules"
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <rule-list />
  </div>
</template>

<script>

import {
  VBToggle, BRow, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RuleList from '@/views/rule/rule-list/RuleList.vue'
import RuleModal from '@/views/rule/rule-create/RuleModal.vue'
import useRuleList from '@/views/rule/useRuleList'

export default {
  name: 'Quiz',
  components: {
    RuleModal,
    RuleList,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  setup() {
    const {
      // Options

      //
      fetchRules,
      refetchRule,
      getListRule,
      perPage,
      currentPage,
      totalRules,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRuleListTable,
      refetchData,

      // Extra Filters
      //
      statusResolver,
      statusNameResolver,

    } = useRuleList()
    fetchRules()

    return {
      // Options

      //
      fetchRules,
      refetchRule,
      getListRule,
      perPage,
      currentPage,
      totalRules,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRuleListTable,
      refetchData,

      // Extra Filters
      //
      statusResolver,
      statusNameResolver,

    }
  },
}
</script>

<style lang="scss">
    @import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
    .item-view-radio-group ::v-deep {
        .btn {
            display: flex;
            align-items: center;
        }
    }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
