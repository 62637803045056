<template>
  <div v-if="Object.values(getListRules()).length > 0">
    <b-row>
      <b-col
        v-for="rule in getListRules()"
        :key="rule.id"
        lg="3"
        md="4"
        sm="6"
        cols="12"
      >
        <b-card
          class="position-static ecommerce-card"
          align="center"
          :title="rule.name"
          border-variant="primary"
        >
          <b-link
            @click="$bvModal.show('modal-rule-edit-'+rule.id)"
          >
            <b-card-text>
              <small class="text-muted">
                Ultimo aggiornamento il {{ rule.updated_at }}
              </small>
            </b-card-text>
          </b-link>
          <!-- Product Actions -->
          <div class="item-options text-center mt-1">
            <b-button
              variant="primary"
              size="sm"
              @click="$bvModal.show('modal-rule-edit-'+rule.id)"
            >
              <span>Vedi</span>
            </b-button>
            <b-button
              v-if="rule.deletable"
              variant="outline-danger"
              size="sm"
              class="ml-1"
              @click="swalConfirmDeleteQuiz(rule.id)"
            >
              <span>Elimina</span>
            </b-button>
          </div>
        </b-card>

        <rule-modal-edit
          :edit-data="rule"
          @refetch-data="fetchRules"
        />
      </b-col>
    </b-row>

  </div>
</template>

<script>

import {
  VBToggle, BCard, BCardText, BRow, BCol, BLink, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import RuleModalEdit from '@/views/rule/rule-edit/RuleModalEdit.vue'
import store from '@/store'
import useRuleList from '@/views/rule/useRuleList'

export default {
  name: 'RuleList',
  components: {
    RuleModalEdit,
    BCard,
    BCardText,
    BRow,
    BCol,
    BLink,
    BButton,
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
  methods: {
    swalConfirmDeleteQuiz(id) {
      this.$swal({
        title: 'Sei sicura/o?',
        text: 'Non potrai annullare questa operazione',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si cancella!',
        cancelButtonText: 'Chiudi',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      })
        .then(result => {
          if (result.value) {
            store.dispatch('rules/deleteRule', id)
              .then(() => {
                this.refetchData()
                this.$swal({
                  icon: 'success',
                  title: 'Cancellato!',
                  text: 'Quiz cancellato',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              })
          } else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Annullata',
              text: 'Cancellazione annullata',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
          return result.value
        })
    },
  },
  setup() {
    const {
      // Options

      //
      fetchRules,
      refetchRule,
      getListRules,
      perPage,
      currentPage,
      totalRules,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRuleListTable,
      refetchData,

      // Extra Filters
      //
      statusResolver,
      statusNameResolver,
    } = useRuleList()

    return {
      // Options

      //
      fetchRules,
      refetchRule,
      getListRules,
      perPage,
      currentPage,
      totalRules,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refRuleListTable,
      refetchData,

      // Extra Filters
      //
      statusResolver,
      statusNameResolver,
    }
  },
}
</script>

<style lang="scss">
    @import "~@core/scss/base/pages/app-ecommerce.scss";
</style>

<style lang="scss" scoped>
    .item-view-radio-group ::v-deep {
        .btn {
            display: flex;
            align-items: center;
        }
    }
</style>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
