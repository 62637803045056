<template>
  <!-- modal -->
  <b-modal
    :id="'modal-rule-edit-'+editData.id"
    :ref="'modal-rule-edit-'+editData.id"
    :title="'Quiz '+editData.name"
    ok-title="Salva"
    cancel-title="Chiudi"
    cancel-variant="outline-secondary"
    size="lg"
    @show="resetModal"
    @hidden="resetModal"
    @ok="handleOk"
  >
    <b-overlay
      id="overlay-background"
      :show="show"
      variant="transparent"
      :opacity="0.85"
      blur="2px"
      rounded="sm"
    >
      <validation-observer ref="createRuleForm">
        <form
          ref="form"
          class="repeater-form"
          @submit.stop.prevent="validationForm"
        >

          <b-row>
            <b-col
              lg="12"
              md="12"
              sm="12"
              cols="12"
            >
              <b-row>
                <b-col
                  lg="6"
                  md="6"
                  sm="12"
                  cols="12"
                >
                  <b-carousel
                    id="carousel-caption"
                    :controls="editData.link && editData.link.length > 1"
                    :indicators="editData.link && editData.link.length > 1"
                    img-width="250"
                    img-height="250"
                    style="max-width: 500px"
                    class="m-auto"
                  >
                    <b-carousel-slide
                      v-for="url in editData.link"
                      :key="url"
                      class="card-img-overlay"
                      :img-src="url"
                    />
                  </b-carousel>
                </b-col>
                <b-col
                  lg="6"
                  md="6"
                  sm="12"
                  cols="12"
                >
                  <b-row>
                    <b-col
                      lg="12"
                      md="12"
                      sm="12"
                      cols="12"
                    >
                      <b-img-lazy
                        class="m-auto"
                        style="width: 100%"
                        :src="makeUrlForPreview()"
                      />
                    </b-col>
                    <b-col
                      lg="12"
                      md="12"
                      sm="12"
                      cols="12"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="image"
                        rules=""
                      >
                        <b-form-group
                          label="Regolamento JPG, PNG o PDF"
                          description="(i PDF verranno convertiti in automatico)"
                          label-for="image-input"
                          invalid-feedback="Obbligatorio"
                        >
                          <b-form-file
                            v-model="sendData.file"
                            :state="getValidationState(validationContext)"
                            placeholder="Scegli documento"
                            accept=".pdf, .png, .jpg"
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                    <b-col
                      lg="12"
                      md="12"
                      sm="12"
                      cols="12"
                    >
                      <validation-provider
                        #default="validationContext"
                        name="name"
                        rules=""
                      >
                        <b-form-group
                          label="Nome"
                          label-for="name"
                          invalid-feedback="Obbligatorio"
                        >
                          <b-form-input
                            id="name"
                            v-model="sendData.name"
                            :state="getValidationState(validationContext)"
                            required
                          />
                        </b-form-group>
                      </validation-provider>
                    </b-col>
                  </b-row>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import {
  BFormGroup, BFormInput, BModal, VBModal, BRow, BCol, BOverlay, BImgLazy, BFormFile, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import {
  required, alphaNum, integer, digits,
} from '@validations'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormGroup,
    BFormInput,
    BModal,
    BRow,
    BCol,
    BOverlay,
    BImgLazy,
    BFormFile,
    BCarousel,
    BCarouselSlide,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    editData: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      integer,
      digits,
    }
  },
  methods: {

    validationForm() {
      this.$refs.createRuleForm.validate()
        .then(success => {
          if (success) {
            this.handleSubmit()
          }
        })
    },
    resetModal() {
      // this.resetsendData()
    },
    handleOk(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      // Trigger submit handler
      this.validationForm()
    },
    handleSubmit() {
      this.onSubmit()
    },
  },
  setup(props, {
    emit,
    refs,
  }) {
    // Use toast
    const toast = useToast()

    const show = ref(false)

    const blankSendData = {
      id: props.editData.id,
      name: props.editData.name,
      file: null,
    }

    const sendData = ref(JSON.parse(JSON.stringify(blankSendData)))
    const resetsendData = () => {
      sendData.value = JSON.parse(JSON.stringify(blankSendData))
    }

    const makeUrlForPreview = () => {
      if (sendData.value.file && (sendData.value.file.type === 'image/png' || sendData.value.file.type === 'image/jpeg')) {
        return URL.createObjectURL(sendData.value.file)
      }
      // eslint-disable-next-line global-require
      return require('@/assets/images/placeholder/placeholder-image.jpeg')
    }

    const onSubmit = () => {
      show.value = !show.value
      const formData = new FormData()
      formData.append('id', sendData.value.id)
      if (sendData.value.name) formData.append('name', sendData.value.name)
      if (sendData.value.file) formData.append('file', sendData.value.file)
      store.dispatch('rules/updateRule', formData)
        .then(() => {
          emit('refetch-data')
          refs[`modal-rule-edit-${props.editData.id}`].toggle('#toggle-btn')
          resetsendData()
          show.value = false
        })
        .catch(error => {
          Object.values(error.response.data)
            .forEach(message => {
              toast({
                component: ToastificationContent,
                props: {
                  title: message[0],
                  icon: 'AlertTriangleIcon',
                  variant: 'warning',
                },
              })
            })
          show.value = false
        })
    }

    const {
      refFormObserver,
      getValidationState,
      resetForm,
    } = formValidation(resetsendData)

    return {
      show,

      sendData,
      makeUrlForPreview,
      onSubmit,

      refFormObserver,
      getValidationState,
      resetForm,
      resetsendData,
    }
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>
